import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "提示内容" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx._placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._placeholder) = $event)),
          size: "default",
          placeholder: "请设置提示内容"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "文字颜色" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_color_picker, {
          modelValue: _ctx._value.color,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx._value.color) = $event)),
          size: "default"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}